function clickProductsFilterType() {
  const btn = document.querySelector('[data-products-filter-btn]'),
    filterTypes = document.querySelectorAll('[data-products-filter]');
  const params = new URLSearchParams(window.location.search);

  if (btn) {
    for (let i = 0; i < filterTypes.length; i++) {
      let filterType = filterTypes[i];

      let currentCountry = params.get('country');

      if (filterType.dataset.productsFilter === currentCountry) {
        btn.innerHTML = filterType.innerHTML;
      }

      filterType.addEventListener('click', () => {
        let type = filterType.innerHTML;
        btn.innerHTML = type;

        // set search query
        params.delete('category');
        params.set('country', filterType.dataset.productsFilter)
        window.location.search = params.toString()
      });
    }
  }
}

function clickProductCategoryFilter() {
  const items = document.querySelectorAll('#productsTab button.products__nav-btn')
  const params = new URLSearchParams(window.location.search);
  let currentCategory = params.get('category')

  if (items) {
    for (let i = 0; i < items.length; i++) {
      let item = items[i]

      if (currentCategory === item.id) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }

      item.addEventListener('click', () => {
        params.set('category', item.id)
        window.location.search = params.toString()
      })
    }
  }
}

clickProductsFilterType();
clickProductCategoryFilter();
