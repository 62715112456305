// import {
//   debounce
// } from './corporate/utils';
import {
  openSubMenu,
  addClassNavBar,
  removeClassNavBar
} from './corporate/navigation';
import './corporate/header';
import './corporate/content-media';
import './corporate/slider';
import './corporate/products';

// Global variables
const yCoord = window.pageYOffset;

// Init actions
openSubMenu();

if (yCoord > 5) {
  addClassNavBar();
} else {
  removeClassNavBar();
}

// Actions on resize
window.addEventListener('resize', () => {

});

// Actions on scroll
window.addEventListener('scroll', () => {
  const yCoord = window.pageYOffset;

  if (yCoord > 5) {
    addClassNavBar();
  } else {
    removeClassNavBar();
  }
});
