function scrollToParagraph () {
  const mainAnchor = document.getElementById('main'),
        btn = document.querySelector('[data-scroll-to]');

  if (btn) {
    btn.addEventListener('click', () => {
      mainAnchor.scrollIntoView({
        behavior: 'smooth'
      });
    });
  }
}

scrollToParagraph();
