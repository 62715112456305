import { tns } from '../../../../../node_modules/tiny-slider/src/tiny-slider.js';

function playContentMediaVideo () {
  const elements = document.querySelectorAll('[data-vid]');

  if (elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      let parent = elements[i],
          button = parent.querySelector('[data-button="video-button"]'),
          video = parent.querySelector('[data-video]');

      button.addEventListener('click', () => {
        parent.classList.add('--is-playing');
        video.play();
      });
    }
  }
}

function contentMediaSlider () {
  const carousels = document.querySelectorAll('.content-media__carousel');

  if (carousels.length > 0) {
    for (let i = 0; i < carousels.length; i++) {
      let container = '[data-carousel-' + (i + 1) + ']';

      let options = {
        container: container,
        mode: 'gallery',
        loop: false,
        items: 1,
        controls: true,
        controlPosition: 'bottom',
        nav: false,
        speed: 400
      }

      renderSliderContent(options);
    }
  }

  function renderSliderContent(options) {
    const sliderContent = tns(options);
    return sliderContent;
  }
}

contentMediaSlider();
playContentMediaVideo();
